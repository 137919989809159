.navbar {
    position: relative;
  }
  button {
    cursor: pointer;
    background: transparent;;
    border: none;
    font-size: 20px;
  }
  .navbar .toggle {
    position: relative;
    left: 30px;
    top: 40px;
    cursor: pointer;
    background: transparent;;
    border: none;
  }
  .menu-nav {
    list-style: none;
    position: absolute;
    background: #edeff0;
    color: #1B3D38;
    left: 0;
    margin-right: 10px;
    width: 0;
    top: 80px;
    overflow: hidden;
    max-width: 100%;
    text-align: center;
    z-index: 9;
    font-size: 20px;
    box-shadow: 0 10px 15px -3px rgb(46 41 51 / 8%), 0 4px 6px -2px rgb(71 63 79 / 16%);
    transform: translateX(-100px);
    transition: transform ease-in-out 0.2s;
  }

  .menu-nav.show-menu {
    width: 100%;
    transform: translateX(0px);
  }
  .menu-nav li a, .menu-nav li span {
    display: block;
    padding: 1rem;
  }
  
  .menu-nav li {
    padding: 2rem;
  }

  .logo-navbar {
    margin: 0 auto;
  }

@media (max-width:900px) {
    .navbar-desktop{
        visibility: hidden;
    }
}

@media (min-width:900px) {
    .navbar{
        visibility: hidden;
    }
}