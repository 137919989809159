.decoration {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: -1;
}

.headline {
    z-index: 99 !important;
}

.title-header {

}

@media (max-width: 900px) {
    .decoration{
        visibility: hidden;
    };
    .header-font-mobile{
        font-size: 3.5rem;
    }
}


